import Component from '../core/Component'
import Scroll, { scrollTo } from '../services/Scroll'

const STATES = {
    ACTIVE: 'is-active',
}

export default class BackToTop extends Component {
    constructor(element) {
        super(element)

        this.state = {
            windowHeight: window.innerHeight,
            active: false,
        }
    }
    prepare() {
        this.element.addEventListener('click', this.handleClick)
        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleResize = () => {
        this.state.windowHeight = window.innerHeight
    }

    handleScroll = ({ offset }) => {
        this.render(offset.y)
    }

    handleClick = (event) => {
        event.preventDefault()
        scrollTo(0)
    }

    render(offset) {
        if (offset > this.state.windowHeight && !this.state.active) {
            this.state.active = true
            this.element.classList.add(STATES.ACTIVE)
        } else if (offset < this.state.windowHeight && this.state.active) {
            this.state.active = false
            this.element.classList.remove(STATES.ACTIVE)
        }
    }
}
