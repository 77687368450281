import Component from '../core/Component'
import enquire from 'enquire.js'
import { queries } from '../core/config'
import Scroll from '../services/Scroll'

const STATES = {
    ACTIVE: 'is-searchActive',
}

const Events = {
    Open: 'open',
    Close: 'close',
    Toggle: 'toggle',
}

export default class SiteHeader extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            inner: null,
        }

        this.state = {
            height: 0,
            pinned: false,
            active: false,
        }

        this.enquireHandlers = {
            match: () => this.closeSearch(),
        }
    }

    prepare() {
        enquire.register(queries.mediumUp, this.enquireHandlers)
        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
        this.resize()
    }

    destroy() {
        enquire.unregister(queries.mediumUp, this.enquireHandlers)
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleScroll = ({offset}) => {
        this.render(offset.y)
    }

    handleResize = () => {
        this.resize()
    }

    resize() {
        const box = this.element.getBoundingClientRect()

        this.state.height = box.height
        this.render(Scroll.getScroll().y)
    }

    openSearch() {
        this.ref.inner.classList.add(STATES.ACTIVE)
        this.emit(Events.Open)
    }

    closeSearch() {
        this.ref.inner.classList.remove(STATES.ACTIVE)
        this.emit(Events.Close)
    }

    toggleSearch() {
        this.ref.inner.classList.toggle(STATES.ACTIVE)
        this.emit(Events.Toggle)
    }

    render(y) {

        if (y > this.state.height && !this.state.pinned) {
            this.element.classList.add('is-pinned')
            this.state.pinned = true
        }

        if (y < this.state.height && this.state.pinned) {
            this.element.classList.remove('is-pinned')
            this.state.pinned = false
        }

        if (y > this.state.height * 2 && !this.state.active) {
            this.element.classList.add('is-active')
            this.state.active = true
        }

        if (y < this.state.height * 2 && this.state.active) {
            this.element.classList.remove('is-active')
            this.state.active = false
        }
    }
}
