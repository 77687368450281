import Component from '../core/Component'

export const STATES = {
    SEARCH_ACTIVE: 'is-searchActive',
}
export default class SearchOpener extends Component {
    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    handleClick = (event) => {
        event.preventDefault()

        const header = Component.getFromElement(document.getElementById('site-header'))

        header?.toggleSearch()
    }
}
